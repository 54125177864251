.customer-thanks {
  display: flex;
  flex-direction: column;
  align-content: center;
  align-items: center;
  justify-content: stretch;
}

.customer-thanks-title {
  width: 100%;
}
