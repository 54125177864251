.pagination li.pagination-drop-down {
  width: 9rem;
  margin-right: 1rem;
  margin-left: 1rem;
}

.pagination-drop-down label {
  position: relative;
  width: 100%;
}

.pagination-drop-down select {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
}

.button-role-pagination {
  border-radius: var(--input-border-radius);
}

@media screen and (max-width: 500px) {
  .pagination button .button-label {
    display: none;
  }
}
