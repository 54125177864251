.input-select {
  position: relative;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: center;
  align-items: center;
  justify-content: flex-start;
}

.input-select-control {
  flex: 1 0 auto;
}

.input-select-actions {
  display: flex;
  flex: 0 0 auto;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: center;
  align-items: center;
  justify-content: center;
}

.input-select select {
  display: block;
  flex: 1 0 auto;
  appearance: none;
}

.input-select-arrow {
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: center;
  align-items: center;
  justify-content: flex-end;
  height: 100%;

  padding: var(--input-padding-y) calc(0.5 * var(--input-padding-x));
  pointer-events: none;
  touch-action: none;
}

[dir="rtl"] .input-select-arrow {
  right: auto;
  left: 0;
}

/** Hide elements based on state */
.input-select.is-clearable.has-value .input-select-arrow,
.input-select .clear-input {
  display: none;
}

.input-select.has-value .clear-input {
  display: flex;
}
