/** Button reset */
button {
  position: relative;
  display: inline-block;
  margin: 0;
  padding: 0;
  color: inherit;
  font-size: inherit;
  background: transparent;
  border: none;
}

/** Base button */
.button {
  --text-color: var(--dark-color);
  --background-color: transparent;
  --border-color: transparent;
  --size-modifier: var(--size-modifier-m);
  --text-size-modifier: var(--size-modifier-m);

  display: inline-flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: center;
  align-items: center;
  justify-content: center;
  max-width: 100%;
  color: var(--text-color);
  font-size: calc(1rem * var(--text-size-modifier));
  background-color: transparent;
  border-color: transparent;
}

.button-label {
  display: inline-block;
  overflow: hidden;
  font-size: inherit;
  white-space: pre;
  text-overflow: ellipsis;
}

/** Left-indent on label or icon when adjacent to label/icon */
.button .button-label + .icon,
.button .icon + .button-label {
  margin-left: 0.5rem;
}

[dir="rtl"] .button .button-label + .icon,
[dir="rtl"] .button .icon + .button-label {
  margin-right: 0.5rem;
  margin-left: unset;
}

/** Sizes influence the physical shape (note only using variables here) */
.button-size-xxs {
  --size-modifier: var(--size-modifier-xxs);
  --text-size-modifier: var(--size-modifier-xxs);
}

.button-size-xs {
  --size-modifier: var(--size-modifier-xs);
  --text-size-modifier: var(--size-modifier-xs);
}

.button-size-s {
  --size-modifier: var(--size-modifier-s);
  --text-size-modifier: var(--size-modifier-s);
}

.button-size-m {
  --size-modifier: var(--size-modifier-m);
  --text-size-modifier: var(--size-modifier-m);
}

.button-size-l {
  --size-modifier: var(--size-modifier-l);
  --text-size-modifier: var(--size-modifier-l);
}

.button-size-xl {
  --size-modifier: var(--size-modifier-xl);
  --text-size-modifier: var(--size-modifier-xl);
}

.button-size-xxl {
  --size-modifier: var(--size-modifier-xxl);
  --text-size-modifier: var(--size-modifier-xxl);
}

/** Themes control the colours (note only using variables here) */
.button-theme-dark {
  --text-color: var(--dark-text-color);
  --background-color: var(--dark-background-color);
  --border-color: var(--dark-background-color);
}

.button-theme-neutral {
  --text-color: var(--neutral-text-color);
  --background-color: var(--neutral-background-color);
  --border-color: var(--neutral-background-color);
}

.button-theme-light {
  --text-color: var(--light-text-color);
  --background-color: var(--light-background-color);
  --border-color: var(--light-background-color);
}

.button-theme-primary {
  --text-color: var(--primary-text-color);
  --background-color: var(--primary-background-color);
  --border-color: var(--primary-background-color);
}

.button-theme-secondary {
  --text-color: var(--secondary-text-color);
  --background-color: var(--secondary-background-color);
  --border-color: var(--secondary-background-color);
}

.button-theme-tertiary {
  --text-color: var(--tertiary-text-color);
  --background-color: var(--tertiary-background-color);
  --border-color: var(--tertiary-background-color);
}

/** Different shapes/forms (these reference the variables) */
.button-shape-default {
  min-height: var(--button-height);
  padding: calc(0.5rem * var(--size-modifier)) calc(1rem * var(--size-modifier));
  color: var(--background-color);
  font-size: calc(1rem * var(--text-size-modifier));
}

.button-shape-solid {
  min-height: var(--button-height);
  padding: calc(0.5rem * var(--size-modifier)) calc(1rem * var(--size-modifier));
  color: var(--text-color);
  font-size: calc(1rem * var(--text-size-modifier));
  background: var(--background-color);
  border: solid 1px var(--border-color);
}

.button-shape-outline {
  min-height: var(--button-height);
  padding: calc(0.5rem * var(--size-modifier)) calc(1rem * var(--size-modifier));
  color: var(--background-color);
  font-size: calc(1rem * var(--text-size-modifier));
  background: transparent;
  border: solid 1px var(--background-color);
}

/** Should look like a regular text <a> link */
.button-shape-link {
  display: inline;
  min-height: none;
  margin: 0;
  padding: 0;
  color: inherit;
  font-size: inherit;
  line-height: inherit;
  letter-spacing: inherit;
  text-decoration: underline;
  background: transparent;
  border: none;
}

.button-shape-link .button-label {
  display: inline;
  margin: 0;
  padding: 0;
  color: inherit;
  font-size: inherit;
  line-height: inherit;
  text-decoration: underline;
  background: transparent;
  border: none;
}

.button-shape-none {
  display: inline-block;
  padding: 0;
  color: inherit;
  background: transparent;
  border: none;
}

/** Modes are extra modifiers that can influence the shape, theme, size, etc. */
.button.is-inline {
  display: inline-block;
}

.button.is-fullwidth {
  display: block;
  width: 100%;
}

.button:disabled,
.button.is-disabled {
  opacity: 0.5 !important;
  pointer-events: none;
  touch-action: none;
}

/** Now we can combine all of them for specific contextual overrides */
.button-shape-url.button-theme-primary,
.button-shape-url.button-theme-secondary,
.button-shape-url.button-theme-tertiary,
.button-shape-url.button-theme-light,
.button-shape-url.button-theme-neutral,
.button-shape-url.button-theme-dark {
  color: var(--background-color);
}

/** Alternative contextual roles */
.button.button-role-cancel {
  --text-size-modifier: var(--size-modifier-s);
  color: var(--tertiary-color);
  background: transparent;
  border: none;
}
