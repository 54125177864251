.icon {
  --foreground-color: var(--dark-color);
  --background-color: transparent;
  --size-modifier: var(--size-modifier-m);

  display: inline-block;
  width: calc(1rem * var(--size-modifier));
  height: calc(1rem * var(--size-modifier));
  color: var(--foreground-color);
  background-color: var(--background-color);
  /* background-image: none; */
  background-repeat: no-repeat;
  background-position: center center;
  background-size: contain;
}

.icon svg,
.icon img,
.icon canvas {
  max-width: 100%;
  max-height: 100%;
}

/** Size */
.icon-size-xxs {
  --size-modifier: var(--size-modifier-xxs);
}

.icon-size-xs {
  --size-modifier: var(--size-modifier-xs);
}

.icon-size-s {
  --size-modifier: var(--size-modifier-s);
}

.icon-size-m {
  --size-modifier: var(--size-modifier-m);
}

.icon-size-l {
  --size-modifier: var(--size-modifier-l);
}

.icon-size-xl {
  --size-modifier: var(--size-modifier-xl);
}

.icon-size-xxl {
  --size-modifier: var(--size-modifier-xxl);
}

/** Themes can load in different icon fonts, spritesheets and/or change the colours */
.icon-theme-primary {
  --foreground-color: var(--primary-color);
}

.icon-theme-secondary {
  --foreground-color: var(--secondary-color);
}

.icon-theme-tertiary {
  --foreground-color: var(--tertiary-color);
}

.icon-theme-dark {
  --foreground-color: var(--dark-color);
}

.icon-theme-neutral {
  --foreground-color: var(--neutral-color);
}

.icon-theme-light {
  --foreground-color: var(--light-color);
}

/** Names can target specific areas of the theme spritesheet */
/** In this example, I've encoded SVG code as a data URI in the background image */
.icon-name-test {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='512' height='512' viewBox='0 0 512 512'%3e%3cpath d='M224 387.814V512L32 320l192-192v126.912C447.375 260.152 437.794 103.016 380.93 0 521.287 151.707 491.48 394.785 224 387.814z' fill='currentColor' /%3e%3c/svg%3e");
}

.icon-name-close,
.icon-name-delete,
.icon-name-clear {
  /* @TODO */
}

.icon-name-bell,
.icon-name-notification {
  /* @TODO */
}

.icon-name-bell-unread,
.icon-name-notification-unread {
  /* @TODO */
}

.icon-name-arrow-left {
  /* @TODO */
}

.icon-name-arrow-right {
  /* @TODO */
}

.icon-name-chevron-up,
.icon-name-caret-up {
  /* @TODO */
}

.icon-name-chevron-down,
.icon-name-caret-down {
  /* @TODO */
}

.icon-name-check,
.icon-name-success {
  background-image: var(--icon-dark-tick-url);
}

.icon-name-find,
.icon-name-search {
  /* @TODO */
}

.icon-name-filter {
  /* @TODO */
}

.icon-name-id,
.icon-name-identifier {
  /* @TODO */
}

.icon-name-shop,
.icon-name-store,
.icon-name-shopping,
.icon-name-shopping-bag {
  /* @TODO */
}

.icon-name-locale,
.icon-name-language {
  /* @TODO */
}

.icon-name-place,
.icon-name-location,
.icon-name-map-indicator {
  /* @TODO */
}

.icon-name-logout,
.icon-name-exit,
.icon-name-exit-left {
  /* @TODO */
}

.icon-name-cog,
.icon-name-setup,
.icon-name-options,
.icon-name-settings,
.icon-name-configure {
  /* @TODO */
}

.icon-name-wechat {
  /* @TODO */
}

.icon-name-line {
  /* @TODO */
}
