:root {
  --modal-min-width: var(--width-constrained);
  --modal-max-width: var(--width-readable);

  --modal-overlay-background-color: rgba(0, 0, 0, 0.5);
  --modal-overlay-padding: 1rem;

  --modal-background-color: var(--light-color);
  --modal-border: none;
  --modal-padding: 2rem;
}

@media screen and (max-width: 500px) {
  :root {
    --modal-min-width: 100%;
    --modal-max-width: 100%;
    --modal-padding: 1rem;
  }
}

.ReactModalPortal {
  opacity: 0.999; /* Fixes a bug where InputSelectCustom's menu within a modal appears under the Modal overlay */
}

.app {
  opacity: 0.998; /* Makes sure AppHeader appears under the Modal overlay */
} /* see reasoning here:  https://philipwalton.com/articles/what-no-one-told-you-about-z-index/ */

.modal-overlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 2;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-content: center;
  align-items: center;
  justify-content: center;

  padding: var(--modal-overlay-padding);
  background: var(--modal-overlay-background-color);
}

.modal {
  min-width: var(--modal-min-width);
  max-width: var(--modal-max-width);
  min-height: 300px;
  max-height: 700px;

  padding: var(--modal-padding);
  overflow: auto;
  background: var(--modal-background-color);
  border: var(--modal-border);
  border-radius: 0;
  outline: none;
  box-shadow: 0 0 4rem var(--modal-overlay-background-color), 0 0 4rem var(--modal-overlay-background-color);
  -webkit-overflow-scrolling: touch;
}

body.modal-is-open {
  overflow: hidden;
}
