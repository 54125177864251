.page {
  width: 100%;
  margin: 0 auto;
  padding: 1rem;
}

.page-header,
.page-footer {
  width: 100%;
  margin: 0 auto;
}

.page-title {
  margin-top: 0;
  margin-bottom: 2rem;
  text-align: center;
}

.page-content {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-content: center;
  align-items: stretch;
  justify-content: stretch;

  max-width: var(--width-max-content);
  margin: 0 auto;
}

/** `xs` and `s` devices only */
@media screen and (max-width: 500px) {
  .page-content {
    width: 100%;
    max-width: none;
  }
}

/** `s` and up devices */
@media screen and (min-width: 400px) {
  .page {
    padding: 2rem 1rem;
  }
}

/* @media screen and (max-width: 768px) {
  .page {
    overflow: hidden;
  }
} */
