:root {
  --app-header-height: 100px;
  --app-logo-url: none;
  /* @TODO when we get white label design */
  --app-logo-width: 100px;
  --app-logo-height: 50px;
}

.app {
  display: flex;
  flex-direction: column;
  align-content: stretch;
  align-items: stretch;
  justify-content: stretch;

  width: 100%;
  min-width: 100vw;
  max-width: 100vw;
  min-height: 100vh;
}

.app-header {
  position: fixed;
  top: 0;
  z-index: 2;
  display: flex;
  flex: 0 0 auto;
  flex-direction: row;
  align-content: stretch;
  align-items: stretch;
  justify-content: space-between;

  width: 100%;
  height: calc(var(--app-header-height) + env(safe-area-inset-top));
  padding-top: env(safe-area-inset-top);
  color: var(--dark-color);
  background-color: var(--light-color);
}

.app-header-left {
  display: flex;
  flex-direction: row;
  align-content: center;
  align-items: center;
  justify-content: left;
}

.app-header-center {
  position: absolute;
  top: 0;
  left: 50%;
  display: flex;
  flex-direction: column;
  align-content: center;
  align-items: center;
  justify-content: center;

  width: var(--app-logo-width);
  height: 100%;
  /* z-index: 1; */
  margin: 0 calc(-0.5 * var(--app-logo-width));
  padding: 0;
  pointer-events: none;
  touch-action: none;
}

.app-header-right {
  display: flex;
  flex-direction: row;
  align-content: center;
  align-items: center;
  justify-content: right;
}

.app-logo {
  display: block;
  width: var(--app-logo-width);
  height: var(--app-logo-height);
  margin: env(safe-area-inset-top) 0 0;
  padding: 0;
  overflow: hidden;
  text-indent: -9999em;
  background: transparent var(--app-logo-url) no-repeat center center;
  background-size: contain;
}

.app-main {
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  align-content: stretch;
  align-items: stretch;
  justify-content: stretch;

  /* With a fixed header, we need to put a space at the top to account for it */
  padding-top: calc(var(--app-header-height) + env(safe-area-inset-top));
}

.app-main > .notification {
  width: 100%;
}

.app-header-left .button.action-notifications.has-icon .button-label,
.app-header-right .button.action-notifications.has-icon .button-label {
  /** .sr-only */
  position: absolute !important;
  width: 1px !important;
  height: 1px !important;
  padding: 0 !important;
  overflow: hidden !important;
  white-space: nowrap !important;
  border: 0 !important;
  clip: rect(0, 0, 0, 0) !important;
}

@media screen and (max-width: 500px) {
  /** Hide button labels if button has an icon */
  .app-header-left .button.has-icon .button-label,
  .app-header-right .button.has-icon .button-label {
    /** .sr-only */
    position: absolute !important;
    width: 1px !important;
    height: 1px !important;
    padding: 0 !important;
    overflow: hidden !important;
    white-space: nowrap !important;
    border: 0 !important;
    clip: rect(0, 0, 0, 0) !important;
  }
}
