/**
 * # Base app styles
 * 
 * This file contains all the base shared styles and variables.
 * 
 * Each component (if it requires styles) should have its own CSS file which defines
 * its own styles and variables.
 * 
 * This means that precedence will go in order of:
 * 
 *   1. Base shared styles and variables:
 *      `/src/index.css`
 * 
 *   2. Base component styles and variables:
 *      `/src/components/<component_name>/<component_name>.css`
 * 
 *   3. Brand styles and variables:
 *      `/src/config/<brand_id>/index.css`
 * 
 * # Responsive
 * 
 * Default maximum width is 320px.
 * 
 * Since CSS Variables aren't supported in media queries means all media
 * queries in this file (or in component CSS files) are hard-coded.
 * 
 * Brand styles will generally need to refer to the same breakpoints. Some
 * variables such as `--width-device-<size_keyword>` are defined in this file for
 * reference purposes only.
 */

@import url("./config/shared/icons/index.css");

:root {
  /** Colors */
  --dark-color: #111;
  --neutral-color: #ccc;
  --light-color: #fafafa;
  --primary-color: #1300ff;
  --secondary-color: #997c4c;
  --tertiary-color: #666;
  --success-color: #04b44f;
  --warning-color: #ffb414;
  --error-color: #bc0000;
  --info-color: #0066b3;

  /** Theme colours */
  --primary-text-color: var(--light-color);
  --primary-background-color: var(--primary-color);
  --primary-border-color: var(--primary-color);

  --secondary-text-color: var(--light-color);
  --secondary-background-color: var(--secondary-color);
  --secondary-border-color: var(--secondary-color);

  --tertiary-text-color: var(--light-color);
  --tertiary-background-color: var(--tertiary-color);
  --tertiary-border-color: var(--tertiary-color);

  --dark-text-color: var(--light-color);
  --dark-background-color: var(--dark-color);
  --dark-border-color: var(--dark-color);

  --neutral-text-color: var(--dark-color);
  --neutral-background-color: var(--neutral-color);
  --neutral-border-color: var(--neutral-color);

  --light-text-color: var(--dark-color);
  --light-background-color: var(--light-color);
  --light-border-color: var(--light-color);

  --success-text-color: var(--light-color);
  --success-background-color: var(--success-color);
  --success-border-color: var(--success-color);

  --warning-text-color: var(--dark-color);
  --warning-background-color: var(--warning-color);
  --warning-border-color: var(--warning-color);

  --error-text-color: var(--light-color);
  --error-background-color: var(--error-color);
  --error-border-color: var(--error-color);

  --info-text-color: var(--light-color);
  --info-background-color: var(--info-color);
  --info-border-color: var(--info-color);

  /** Size modifiers */
  --size-modifier-xxs: 0.7;
  --size-modifier-xs: 0.8;
  --size-modifier-s: 0.9;
  --size-modifier-m: 1;
  --size-modifier-l: 1.2;
  --size-modifier-xl: 1.4;
  --size-modifier-xxl: 1.6;

  /** Fonts */
  --font-family-base: "Helvetica Neue", Helvetica, Arial, sans-serif;
  --font-family-title: inherit;
  --font-family-readable: inherit;
  --font-family-label: inherit;
  --font-family-button: inherit;
  --font-family-input: inherit;

  --font-size-base: 18px;
  --font-size-h1: 1.4rem;
  --font-size-h2: 1.2rem;
  --font-size-h3: 1.1rem;
  --font-size-h4: 1rem;
  --font-size-h5: 1rem;
  --font-size-h6: 1rem;
  --font-size-readable: 1rem;
  --font-size-label: 1rem;
  --font-size-button: 1rem;
  --font-size-input: 1rem;

  /** Inputs */
  --input-height: 3rem;
  --input-size-modifier: var(--size-modifier-m);
  --input-text-size-modifier: var(--size-modifier-m);
  --input-text-color: var(--dark-color);
  --input-background-color: var(--light-color);
  --input-background: var(--input-background-color);
  --input-border-color: var(--neutral-color);
  --input-border-style: solid;
  --input-border-width: 1px;
  --input-border-radius: 0;
  --input-border: var(--input-border-style) var(--input-border-width) var(--input-border-color);
  --input-padding-x: 0.5rem;
  --input-padding-y: 0.5rem;
  --input-padding: var(--input-padding-y) var(--input-padding-x);

  /** Input Toggleable (Checkbox, Radio) */
  --input-toggleable-width: 1.5rem;
  --input-toggleable-height: 1.5rem;
  --input-checkbox-width: var(--input-toggleable-width);
  --input-checkbox-height: var(--input-toggleable-width);
  --input-radio-width: var(--input-toggleable-width);
  --input-radio-height: var(--input-toggleable-width);
  --input-switch-width: 2.5rem;
  --input-switch-height: var(--input-toggleable-height);

  /** Input focused */
  --input-focused-text-color: var(--dark-color);
  --input-focused-background-color: var(--light-color);
  --input-focused-border-color: var(--light-color);

  /** Input disabled */
  --input-disabled-text-color: var(--neutral-color);
  --input-disabled-background-color: var(--light-color);
  --input-disabled-border-color: var(--neutral-color);

  /** Buttons */
  --button-height: var(--input-height);

  /** Dimensions */
  /** @NOTE these are in relation to the current device breakpoint, which by default is `xs` */
  --width-content: 320px;
  --width-readable: 100%;
  --width-constrained: 100%;

  /**
  
  These are more for reference, since CSS Variables aren't supported in media queries,
  which means all media queries in this file (or in component CSS files) are hard-coded.

  By design I've tried to leave media queries to only be in `/public/<brand_id>/styles.css`

  Device classed `xs` min 320px wide (default):
    - small phones (portrait)
   */
  --width-device-xs: 320px;
  /**
  Device classed `s` from min 400px wide:
    - small phones (landscape)
    - medium phones (portrait)
    - small tablets (portrait)
  */
  --width-device-s: 400px;
  /**
  Device classed `m` from min 600px wide:
    - medium phones (landscape)
    - large phones (portrait)
    - small tablets (landscape)
    - medium tablets (portrait)
  */
  --width-device-m: 600px;
  /**
  Device classed `l` from min 800px wide:
    - large phones (landscape)
    - medium tablets (landscape)
    - small desktop
  */
  --width-device-l: 800px;
  /**
  Device classed `xl` from min 1000px wide:
    - medium desktop
    - large desktop
  */
  --width-device-xl: 1000px;

  /* AWS Amplify form, see https://github.com/aws-amplify/amplify-js/blob/master/packages/amplify-ui/src/Theme.css */
  --squidInk: var(--primary-color);
  --lightSquidInk: var(--primary-color);
  --body-background: var(--light-color);
  --button-color: var(--white);
  --button-background-color: var(--primary-color);
  --button-click: var(--primary-color);
  --form-color: var(--dark-color);
  --font-family: inherit;
  --input-background-color: var(--light-color);
  --input-color: var(--dark-color);
  --link-color: var(--primary-color);
}

/** `s`: M Phone / S Tablet and above */
@media screen and (min-width: 400px) {
  :root {
    --width-content: 400px;
    --width-readable: 360px;
  }
}

/** Grey area between S Tablet and M Tablet and below */
@media screen and (max-width: 500px) {
  :root {
    --width-constrained: 100%;
  }
}

/** `m`: M Tablet and above */
@media screen and (min-width: 600px) {
  :root {
    --width-content: 600px;
    --width-readable: 560px;
    --width-constrained: 400px;
  }
}

/** `l`: L Tablet / S Desktop and above */
@media screen and (min-width: 800px) {
  :root {
    --width-content: 800px;
    --width-readable: 560px;
    --width-constrained: 400px;
  }
}

/** `xl`: M Desktop and above */
@media screen and (min-width: 1000px) {
  :root {
    --width-content: 1000px;
    --width-readable: 560px;
    --width-constrained: 400px;
  }
}

#root {
  height: 100%;
  min-height: auto;
}

#app-version {
  padding-bottom: env(safe-area-inset-bottom);
  color: var(--neutral-color);
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

[dir="rtl"] {
  direction: rtl;
}

body {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-content: stretch;
  align-items: stretch;
  justify-content: stretch;

  margin: 0;
  padding: 0;
  color: var(--dark-color);
  font-size: var(--font-size-base);
  font-family: var(--font-family-base);
  background: var(--light-color);
}

html,
body {
  width: 100%;
  min-width: var(--width-constrained);
  height: 100%;
}

html,
body,
#root {
  min-height: 100%;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: var(--font-family-title);
}

h1 {
  font-size: var(--font-size-h1);
}

h2 {
  font-size: var(--font-size-h2);
}

h3 {
  font-size: var(--font-size-h3);
}

h4 {
  font-size: var(--font-size-h4);
}

h5 {
  font-size: var(--font-size-h5);
}

h6 {
  font-size: var(--font-size-h6);
}

form {
  width: 100%;
  margin: 0 auto;
  padding: 0;
}

.form-errors {
  display: block;
  margin: 0.5rem 0 1rem;
  padding: 0;
  font-size: 0.9rem;
  list-style-type: none;
}

.form-error {
  display: block;
  margin: 0.5rem 0 0;
  padding: 0;
  color: var(--error-color);
}

.customer-form {
  max-width: 100%;
}

.button-group .button.is-fullwidth + .button.is-fullwidth {
  margin-top: 1rem;
}

.field {
  margin-bottom: 1rem;
}

.field.is-toggleable .field-label .label-text {
  overflow: unset;
  white-space: unset;
  text-overflow: unset;
}

/** Text/select inputs */
input[type="color"],
input[type="date"],
input[type="datetime-local"],
input[type="email"],
input[type="file"],
input[type="month"],
input[type="number"],
input[type="password"],
input[type="search"],
input[type="tel"],
input[type="text"],
input[type="time"],
input[type="url"],
input[type="week"],
select,
textarea {
  display: block;
  padding: var(--input-padding);
  color: var(--input-text-color);
  font-size: var(--font-size-input);
  background-color: var(--input-background-color);
  /* Using `border: var(--input-border)` doesn't seem to cascade correctly here */
  border-color: var(--input-border-color);
  border-style: var(--input-border-style);
  border-width: var(--input-border-width);
  border-radius: var(--input-border-radius) !important;
  appearance: none;
}

input::placeholder,
select::placeholder,
textarea::placeholder,
.input-select-custom__placeholder {
  color: var(--tertiary-color);
  font-style: italic;
}

input[type="color"]:focus,
input[type="date"]:focus,
input[type="datetime-local"]:focus,
input[type="email"]:focus,
input[type="file"]:focus,
input[type="month"]:focus,
input[type="number"]:focus,
input[type="password"]:focus,
input[type="search"]:focus,
input[type="tel"]:focus,
input[type="text"]:focus,
input[type="time"]:focus,
input[type="url"]:focus,
input[type="week"]:focus,
select:focus,
textarea:focus {
  color: var(--input-focused-text-color);
  background-color: var(--input-focused-background-color);
  border-color: var(--input-focused-border-color);
}

input[type="color"]:disabled,
input[type="date"]:disabled,
input[type="datetime-local"]:disabled,
input[type="email"]:disabled,
input[type="file"]:disabled,
input[type="month"]:disabled,
input[type="number"]:disabled,
input[type="password"]:disabled,
input[type="search"]:disabled,
input[type="tel"]:disabled,
input[type="text"]:disabled,
input[type="time"]:disabled,
input[type="url"]:disabled,
input[type="week"]:disabled,
select:disabled,
textarea:disabled {
  color: var(--input-disabled-text-color);
  background-color: var(--input-disabled-background-color);
  border-color: var(--input-disabled-border-color);
  /** required on iOS*/
  -webkit-text-fill-color: var(--input-disabled-text-color);
  opacity: 1;
}

.sr-only {
  position: absolute !important;
  width: 1px !important;
  height: 1px !important;
  padding: 0 !important;
  overflow: hidden !important;
  white-space: nowrap !important;
  border: 0 !important;
  clip: rect(0, 0, 0, 0) !important;
}

.sr-only-focusable:active,
.sr-only-focusable:focus {
  position: static !important;
  width: auto !important;
  height: auto !important;
  overflow: visible !important;
  white-space: normal !important;
  clip: auto !important;
}

/** Special utility helpers */
.-helper-calculate-width {
  position: absolute;
  top: 0;
  left: 0;
  visibility: hidden;
  pointer-events: none;
  touch-action: none;
}

.configurable-form.customer-form .section-text.configurable-form-field p {
  text-align: center;
}

/** Make the customer additional info form goes full screen for devices <500px */
@media screen and (max-width: 500px) {
  .modal.additional-info {
    width: 100%;
    max-width: none;
    height: 100%;
    max-height: none;
    border-radius: 0;
  }

  .modal-overlay.additional-info {
    padding: 0;
  }

  .customer-additional-info-form {
    width: unset;
    max-width: none;
  }
}
