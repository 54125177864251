/**
 *
 * I've opted to assign icon class names based on usage context and purpose
 * and icon variable names based on icon's image content.
 * 
 * Then I set the `background-image` (or if we were using an icon font,
 * we could set the `content` character code) for synonymous contexts/purposes
 * names that the icon could fall under.
 * 
 * Best example to illustrate above is for the "cancel" icon. Traditionally
 * "cancel" is represented by an "X". Alternatively this "X" can be used to
 * represent other visually synonymous actions:
 * 
 *   - Close
 *   - Delete
 *   - Clear (wipe clean)
 *   - Forbidden
 *
 * Supporting both dark and light backgrounds is essential but not always
 * mandatory.
 */
:root {
  /** Dark icons */
  --icon-dark-cancel-url: url("./dark/cancel.svg");
  --icon-dark-bell-url: url("./dark/bell.svg");
  --icon-dark-bell-reminder-url: url("./dark/bellOn.svg");
  --icon-dark-arrow-left-url: url("./dark/leftArrow.svg");
  --icon-dark-arrow-right-url: url("./dark/rightArrow.svg");
  /* @TODO --icon-dark-chevron-up-url: url("./dark/upArrow.svg"); */
  --icon-dark-chevron-down-url: url("./dark/downArrow.svg");
  --icon-dark-magnifying-glass-url: url("./dark/search.svg");
  --icon-dark-filter-url: url("./dark/filter.svg");
  --icon-dark-name-badge-url: url("./dark/identity.svg");
  --icon-dark-shopping-bag-url: url("./dark/store.svg");
  --icon-dark-language-url: url("./dark/language.svg");
  --icon-dark-location-url: url("./dark/location.svg");
  --icon-dark-exit-left-url: url("./dark/logout.svg");
  --icon-dark-cog-url: url("./dark/settings.svg");
  --icon-dark-tick-url: url("./dark/tick.svg");
  --icon-dark-error-url: url("./dark/error.svg");
  --icon-dark-warning-url: url("./dark/warning.svg");
  --icon-dark-success-url: url("./dark/success.svg");
  /** @TODO --icon-dark-info-url: url("./dark/info.svg"); */
  --icon-dark-wechat-url: url("./dark/wechat.svg");
  /* @TODO --icon-dark-line-url: url("./dark/line.svg"); */
  --icon-dark-edit-url: url("./dark/edit.svg");
  /** Light icons */
  --icon-light-cancel-url: url("./light/cancel.svg");
  --icon-light-error-url: url("./light/error.svg");
  --icon-light-warning-url: url("./light/warning.svg");
  --icon-light-success-url: url("./light/success.svg");
  --icon-light-tick-url: url("./light/tick.svg");
  /** @TODO --icon-light-info-url: url("./light/info.svg"); */
}

/** App's icons */
.icon-name-cancel,
.icon-name-close,
.icon-name-delete,
.icon-name-clear {
  background-image: var(--icon-dark-cancel-url);
}

.icon-name-bell,
.icon-name-notification {
  background-image: var(--icon-dark-bell-url);
}

.icon-name-bell-reminder,
.icon-name-notification-unread {
  background-image: var(--icon-dark-bell-reminder-url);
}

.icon-name-arrow-up,
.icon-name-chevron-up,
.icon-name-caret-up {
  background-image: var(--icon-dark-chevron-up-url);
}

.icon-name-arrow-down,
.icon-name-chevron-down,
.icon-name-caret-down {
  background-image: var(--icon-dark-chevron-down-url);
}

.icon-name-arrow-left,
.icon-name-chevron-left,
.icon-name-caret-left {
  background-image: var(--icon-dark-arrow-left-url);
}

.icon-name-arrow-right,
.icon-name-chevron-right,
.icon-name-caret-right {
  background-image: var(--icon-dark-arrow-right-url);
}

.icon-name-find,
.icon-name-search,
.icon-name-magnifiying-glass {
  background-image: var(--icon-dark-magnifying-glass-url);
}

.icon-name-filter {
  background-image: var(--icon-dark-filter-url);
}

.icon-name-id,
.icon-name-identity,
.icon-name-identifier {
  background-image: var(--icon-dark-name-badge-url);
}

.icon-name-shop,
.icon-name-store,
.icon-name-shopping,
.icon-name-shopping-bag {
  background-image: var(--icon-dark-shopping-bag-url);
}

.icon-name-locale,
.icon-name-language {
  background-image: var(--icon-dark-language-url);
}

.icon-name-place,
.icon-name-location,
.icon-name-map-indicator {
  background-image: var(--icon-dark-location-url);
}

.icon-name-logout,
.icon-name-exit,
.icon-name-exit-left {
  background-image: var(--icon-dark-exit-left-url);
}

.icon-name-cog,
.icon-name-setup,
.icon-name-options,
.icon-name-settings,
.icon-name-configure {
  background-image: var(--icon-dark-cog-url);
}

.icon-name-tick,
.icon-name-check,
.icon-name-checkmark {
  background-image: var(--icon-dark-tick-url);
}

.icon-name-edit {
  background-image: var(--icon-dark-edit-url);
}

.icon-name-success {
  background-image: var(--icon-dark-success-url);
}

.icon-name-warning {
  background-image: var(--icon-dark-warning-url);
}

.icon-name-error {
  background-image: var(--icon-dark-error-url);
}

.icon-name-wechat {
  background-image: var(--icon-dark-wechat-url);
}

.icon-name-line {
  background-image: var(--icon-dark-line-url);
}

.icon-name-loading {
  position: relative;
}

.icon-name-loading::after {
  position: absolute;
  top: 50%;
  left: 50%;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  margin-top: -50%;
  margin-left: -50%;
  border: 2px solid rgba(0, 0, 0, 0.2);
  border-top-color: black;
  border-radius: 50%;
  animation: spinner 0.6s linear infinite;
  content: "";
}

@keyframes spinner {
  to {
    transform: rotate(360deg);
  }
}