.pagination {
  margin: 2rem 1rem;
}

.pagination ul {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: center;
  align-items: flex-start;
  justify-content: center;
  margin: 0 auto;
  padding: 0;
  text-align: center;
  list-style-type: none;
}

.pagination li {
  display: flex;
  flex-direction: column;
  align-content: center;
  align-items: center;
  justify-content: center;
  margin: 0;
  padding: 0;
  text-align: center;
  list-style-type: none;
}

.pagination-page-number.current-page {
  color: var(--dark-color);
  background-color: var(--light-color);
}

.pagination-page-back {
  align-self: left;
}

.pagination-page-next {
  align-self: right;
}
