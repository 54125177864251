.modal.loading-screen {
  position: unset;
  top: unset;
  right: unset;
  bottom: unset;
  left: unset;
  z-index: unset;
  background: transparent;
  box-shadow: unset;
}
