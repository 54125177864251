/**
 * This is a restyling of the react-select component.
 * 
 * I've disabled as many of the CSS-in-JS styles as I can to make it more
 * reliant on pure CSS styles for customisation.
 */

.input-select-custom {
  position: relative;
}

/** ReactSelect */
.input-select-custom__control {
  display: flex;
  flex-direction: row;
  align-content: flex-start;
  align-items: center;
  justify-content: flex-start;
  color: var(--input-text-color);
  font-size: var(--font-size-input);
  font-family: var(--font-family-input);
  background: var(--input-background);
  /* Using `border: var(--input-border)` doesn't seem to cascade correctly here */
  border-color: var(--input-border-color);
  border-style: var(--input-border-style);
  border-width: var(--input-border-width);
}

.input-select-custom__control--is-disabled {
  --input-background-color: var(--input-disabled-background-color);
  --input-text-color: var(--input-disabled-text-color);
  --input-border-color: var(--input-disabled-border-color);
  --input-background: var(--input-background-color);
  --input-border: var(--input-border-style) var(--input-border-width) var(--input-border-color);
}

.input-select-custom__control--menu-is-open {
  border-bottom-color: var(--input-background-color);
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.input-select-custom__value-container {
  display: flex;
  flex: 1 1;
  flex-direction: row;
  align-content: flex-start;
  align-items: flex-start;
  justify-content: flex-start;
  max-width: 100%;
  max-height: var(--input-height);
  padding: var(--input-padding);
  overflow: hidden;
  text-overflow: ellipsis;
}

.input-select-custom__value-container--is-multi {
  max-height: none;
}

.input-select-custom__placeholder {
  color: var(--tertiary-color);
  font-style: italic;
}

.input-select-custom__indicators {
  display: flex;
  flex: 0 0 auto;
  flex-direction: row;
  align-content: center;
  align-items: center;
  justify-content: center;
  width: 1.5rem;
}

.input-select-custom__indicator {
  display: inline-flex;
  flex-direction: column;
  align-content: center;
  align-items: center;
  justify-content: center;
  width: 1.5rem;
}

/** Hide if the input has a selected option */
.input-select-custom.is-clearable.has-value .input-select-custom__dropdown-indicator {
  display: none;
}

.input-select-custom__single-value {
  display: inline-block;
  max-width: 100%;
  max-height: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.input-select-custom__multi-value {
  display: inline-block;
  max-width: 100%;
  max-height: 100%;
  margin-right: 0.3rem;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.input-select-custom__multi-value__label {
  display: inline-block;
}

.input-select-custom__multi-value__remove {
  display: inline-block;
}

.input-select-custom__value-container,
.input-select-custom__input {
  display: flex;
  flex-direction: row;
  align-content: center;
  align-items: center;
  justify-content: flex-start;
}

.input-select-custom__menu {
  color: var(--input-text-color);
  background: var(--input-background);
  /* Using `border: var(--input-border)` doesn't seem to cascade correctly here */
  border-color: var(--input-border-color);
  border-style: var(--input-border-style);
  border-width: var(--input-border-width);
  border-top: none;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  box-shadow: 0 1rem 2rem rgba(0, 0, 0, 0.1);
}

.input-select-custom__menu-notice {
  text-align: center;
}

.input-select-custom__group-heading {
  /* margin-bottom: var(--input-padding-y); */
  padding: var(--input-padding-y) var(--input-padding-x);
  color: var(--tertiary-color);
  font-size: var(--font-size-label);
  font-family: var(--font-family-label);
  text-transform: uppercase;
}

.input-select-custom__menu-notice,
.input-select-custom__option {
  min-height: var(--input-height);
  padding: var(--input-padding-y) var(--input-padding-x);
}

.input-select-custom__option--is-focused {
  color: var(--input-focused-text-color);
  background-color: var(--input-focused-background-color);
  border-color: var(--input-focused-border-color);
}

.input-select-custom__group .input-select-custom__option {
  --input-padding-x: calc(1rem * var(--input-size-modifier));
  padding-right: var(--input-padding-x);
  padding-left: var(--input-padding-x);
}

/** Disabled state */
.input-select-custom__control--is-disabled .input-select-custom__placeholder,
.input-select-custom__control--is-disabled .input-select-custom__value-container,
.input-select-custom__control--is-disabled .input-select-custom__indicators {
  opacity: 0.5;
}
