.configurable-form-field.group .group-content {
  display: flex;
  flex-direction: column;
}

.group-content-inline {
  display: flex;
  flex-direction: row;
  gap: 10px;
}

.group-content-inline .configurable-form-field {
  width: 100%;
}
