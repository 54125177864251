.field.field-type-tags label {
  position: relative !important;
}

.input-tags-header {
  position: absolute;
  top: -15px;
  right: 0;
  font-size: 15px;
  text-align: right;
}

.input-tags-header button.button-theme-neutral {
  padding: 0;
  background-color: transparent;
  border: none;
}

.input-tags-header button.button-theme-neutral div {
  text-decoration: underline;
}

.input-tags .tag-wrap {
  display: inline-block;
  margin: 0 10px 10px 0;
}

.input-tags-tags-wrap button.button-theme-primary,
.input-tags-tags-wrap button.button-theme-neutral {
  /* padding: 10px 15px 6px 15px; */
  padding-right: 15px;
  padding-left: 15px;
}
