.loading-screen {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 99999;
  display: flex;
  flex-direction: column;
  align-content: center;
  align-items: center;
  justify-content: center;
  transition: opacity 0.2s;
}

.loading-screen .loading {
  display: flex;
  flex-direction: column;
  align-content: center;
  align-items: center;
  justify-content: center;
}

.loading-screen .icon.icon-name-loading {
  width: 80px;
  height: 80px;
}

.loading-screen.hide-loading-screen {
  opacity: 0;
  user-select: none;
  pointer-events: none;
  touch-action: none;
}
