.input-yearmonthday {
  display: flex;
  flex-direction: row;
  align-content: flex-start;
  align-items: flex-start;
  justify-content: space-between;
}

.input-yearmonthday .subfield {
  width: calc(33% - 0.6rem);
}

/**
 * Localisation ordering
 *
 * See https://en.wikipedia.org/wiki/Date_format_by_country
 */

/** -- DD-MM-YYYY */
.input-yearmonthday .input-day {
  order: 1;
}

.input-yearmonthday .input-month {
  order: 2;
}

.input-yearmonthday .input-year {
  order: 3;
}

/** -- MM-DD-YYYY */
[data-country^="US"] .subfield.input-month,
[data-country^="AS"] .subfield.input-month,
[data-country^="GU"] .subfield.input-month,
[data-country^="MP"] .subfield.input-month,
[data-country^="PR"] .subfield.input-month,
[data-country^="VI"] .subfield.input-month,
[data-country^="PH"] .subfield.input-month,
[data-country^="AT"] .subfield.input-month,
[data-country^="CA"] .subfield.input-month,
[data-country^="DK"] .subfield.input-month,
[data-country^="CN"] .subfield.input-month,
[data-country^="SE"] .subfield.input-month,
[data-country^="TW"] .subfield.input-month,
[data-country^="AE"] .subfield.input-month,
[data-country^="FM"] .subfield.input-month {
  order: 1;
}

[data-country^="US"] .subfield.input-day,
[data-country^="AS"] .subfield.input-day,
[data-country^="GU"] .subfield.input-day,
[data-country^="MP"] .subfield.input-day,
[data-country^="PR"] .subfield.input-day,
[data-country^="VI"] .subfield.input-day,
[data-country^="PH"] .subfield.input-day,
[data-country^="AT"] .subfield.input-day,
[data-country^="CA"] .subfield.input-day,
[data-country^="DK"] .subfield.input-day,
[data-country^="CN"] .subfield.input-day,
[data-country^="SE"] .subfield.input-day,
[data-country^="TW"] .subfield.input-day,
[data-country^="AE"] .subfield.input-day,
[data-country^="FM"] .subfield.input-day {
  order: 2;
}

[data-country^="US"] .subfield.input-year,
[data-country^="AS"] .subfield.input-year,
[data-country^="GU"] .subfield.input-year,
[data-country^="MP"] .subfield.input-year,
[data-country^="PR"] .subfield.input-year,
[data-country^="VI"] .subfield.input-year,
[data-country^="PH"] .subfield.input-year,
[data-country^="AT"] .subfield.input-year,
[data-country^="CA"] .subfield.input-year,
[data-country^="DK"] .subfield.input-year,
[data-country^="CN"] .subfield.input-year,
[data-country^="SE"] .subfield.input-year,
[data-country^="TW"] .subfield.input-year,
[data-country^="AE"] .subfield.input-year,
[data-country^="FM"] .subfield.input-year {
  order: 3;
}

/** -- YYYY-MM-DD */
[data-country^="CN"] .subfield.input-year,
[data-country^="HK"] .subfield.input-year,
[data-country^="MO"] .subfield.input-year,
[data-country^="TW"] .subfield.input-year,
[data-country^="JP"] .subfield.input-year,
[data-country^="KR"] .subfield.input-year,
[data-country^="LT"] .subfield.input-year,
[data-country^="HU"] .subfield.input-year,
[data-country^="IR"] .subfield.input-year,
[data-country^="MN"] .subfield.input-year,
[lang^="ja"] .subfield.input-year {
  order: 1;
}

[data-country^="CN"] .subfield.input-month,
[data-country^="HK"] .subfield.input-month,
[data-country^="MO"] .subfield.input-month,
[data-country^="TW"] .subfield.input-month,
[data-country^="JP"] .subfield.input-month,
[data-country^="KR"] .subfield.input-month,
[data-country^="LT"] .subfield.input-month,
[data-country^="HU"] .subfield.input-month,
[data-country^="IR"] .subfield.input-month,
[data-country^="MN"] .subfield.input-month,
[lang^="ja"] .subfield.input-month {
  order: 2;
}

[data-country^="CN"] .subfield.input-day,
[data-country^="HK"] .subfield.input-day,
[data-country^="MO"] .subfield.input-day,
[data-country^="TW"] .subfield.input-day,
[data-country^="JP"] .subfield.input-day,
[data-country^="KR"] .subfield.input-day,
[data-country^="LT"] .subfield.input-day,
[data-country^="HU"] .subfield.input-day,
[data-country^="IR"] .subfield.input-day,
[data-country^="MN"] .subfield.input-day,
[lang^="ja"] .subfield.input-day {
  order: 3;
}
