.page.page-name-notifications .page-header,
.page.page-name-notifications .page-content {
  position: relative;
  max-width: var(--width-device-m);
}

.page.page-name-notifications .notifications-notice {
  margin-top: 1rem;
  padding: 0 1rem;
  text-align: center;
}

.page.page-name-notifications .page-header .button.notifications-filters-toggle {
  position: absolute;
  top: 0;
  right: 0;
}

[dir="rtl"] .page.page-name-notifications .page-header .button.notifications-filters-toggle {
  right: auto;
  left: 0;
}

.notifications-filters-form {
  display: grid;
  grid-gap: 1rem;
  grid-template-columns: 1fr;
  width: 100%;
  min-width: unset;
  max-width: unset;
  max-height: 0;
  margin: 0 auto;
  overflow: hidden;
  transition: max-height 0.2s;
}

/** Remove the field margin since the CSS grid already has a margin */
.notifications-filters-form .field.has-label:not(.is-toggleable) {
  margin-bottom: 0;
}

/** Make the checkbox filter span the whole row */
.notifications-filters-form .filter-onlymine {
  grid-column: 1 / -1;
}

.notifications-filters-form.is-visible {
  max-height: 99em;
}

.notifications-filters-form .field {
  flex: 1 1 auto;
  align-self: stretch;
  margin: 0;
}

.notifications-list {
  margin: 1rem auto;
}

.notifications-list .empty {
  text-align: center;
}

@media screen and (min-width: 600px) {
  .notifications-filters-form {
    grid-template-columns: 1fr 1fr;
  }
}

@media screen and (min-width: 500px) {
  .page.page-name-notifications .notifications-notice,
  .page.page-name-notifications .notifications-filters-form,
  .page.page-name-notifications .notifications-list {
    max-width: var(--width-readable);
  }
}

@media screen and (max-width: 500px) {
  .page.page-name-notifications,
  .page.page-name-notifications .notifications-notice,
  .page.page-name-notifications .notifications-filters-form,
  .page.page-name-notifications .notifications-list,
  .page.page-name-notifications .page-header {
    width: 100%;
    max-width: none;
  }

  .page.page-name-notifications .page-title {
    padding-top: 3rem;
  }

  .notification-title {
    margin-top: 1.5rem;
  }

  .notification::before {
    top: 1.5rem;
  }
}
