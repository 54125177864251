.input-toggleable {
  position: relative;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: flex-start;
  align-items: flex-start;
  justify-content: stretch;
}

/** Hide the input field */
.input-toggleable input {
  /** .sr-only */
  position: absolute !important;
  width: 1px !important;
  height: 1px !important;
  padding: 0 !important;
  overflow: hidden !important;
  white-space: nowrap !important;
  border: 0 !important;
  clip: rect(0, 0, 0, 0) !important;
}

.input-toggleable-control {
  position: relative;
  display: inline-block;
  width: var(--input-toggleable-width);
  height: var(--input-toggleable-height);
  overflow: hidden;
  background: var(--input-background);
  /* Using `border: var(--input-border)` doesn't seem to cascade correctly here */
  border-color: var(--input-border-color);
  border-style: var(--input-border-style);
  border-width: var(--input-border-width);
  border-radius: var(--input-border-radius);
  content: "";
}

.input-toggleable-control::before {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  color: var(--input-text-color);
  border: 0;
  border-radius: var(--input-border-radius);
  content: "";
}

[dir="rtl"] .input-toggleable-control::before {
  right: 0;
  left: auto;
}

.input-toggleable-label {
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: flex-start;
  min-height: var(--input-toggleable-height);
  margin-left: 0.5rem;
}

[dir="rtl"] .input-toggleable-label {
  margin-right: 0.5rem;
  margin-left: initial;
}

.input-toggleable.is-disabled .input-toggleable-control,
.input-toggleable.is-disabled .input-toggleable-label {
  opacity: 0.5;
}

/** Checkbox */
.input-type-checkbox.is-checked:not(.input-switch) .input-toggleable-control::before {
  content: "✓";
  /* background-image: var(--icon-dark-tick-url); */
}

/** Radio */
.input-type-radio:not(.is-switch) .input-toggleable-control,
.input-type-radio:not(.is-switch) .input-toggleable-control::before {
  border-radius: 100%;
}

.input-type-radio.is-checked:not(.is-switch) .input-toggleable-control::before {
  content: "●";
}

/** Switch mode */
.input-toggleable.is-switch .input-toggleable-control {
  width: var(--input-switch-width);
  height: var(--input-switch-height);
  /* Using `border: var(--input-border)` doesn't seem to cascade correctly here */
  border-color: var(--input-border-color);
  border-style: var(--input-border-style);
  border-width: var(--input-border-width);
  border-radius: var(--input-border-radius);
}

.input-toggleable.is-switch .input-toggleable-label {
  min-height: var(--input-switch-height);
}

.input-toggleable.is-switch .input-toggleable-control::before {
  position: absolute;
  top: 20%;
  left: 10%;
  width: 30%;
  height: 60%;
  color: var(--light-color);
  font-size: 0.6rem;
  background: var(--input-text-color);
  border: none;
  border-radius: var(--input-border-radius);
  transition: background-color 0.2s, color 0.2s, left 0.2s, right 0.2s;
  content: "";
}

[dir="rtl"] .input-toggleable.is-switch .input-toggleable-control::before {
  right: 10%;
  left: auto;
}

.input-toggleable.is-switch.is-checked .input-toggleable-control::before {
  left: 60%;
  color: var(--light-color);
  background-color: var(--success-color);
  content: "";
}

[dir="rtl"] .input-toggleable.is-switch.is-checked .input-toggleable-control::before {
  right: 60%;
  left: auto;
  background-color: var(--success-color);
}
